import "babel-polyfill";
import Vue from "vue";
import VueRx from "vue-rx";
import App from "@/App.vue";
import router from "@core/router";
import store from "@core/store/store";
import vuetify from "@/plugins/vuetify";
import { ApiPlugin } from "@core/api";
import { shortLocalDate, dashIfEmpty } from "@/filters";
import Vuelidate from "vuelidate";
import VMask from "v-mask";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-components";
import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

const browserTracing = new Integrations.BrowserTracing();
browserTracing.options.markBackgroundTransactions = false;
Sentry.init({
  dsn: "https://275834bcc8f94fc485efc6f12277a680@o516067.ingest.sentry.io/5621950",
  release: "pan-vue-tachyon@" + "1.3.1",
  environment: process.env.VUE_APP_BACKEND,
  // ...

  integrations: [browserTracing],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  Vue,
  tracingOptions: {
    trackComponents: true,
  },
  logErrors: true,
});

Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_REGION,
    userPoolId: process.env.VUE_APP_STANDARD_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_APP_STANDARD_CLIENT_ID,
  },
});

Vue.config.productionTip = false;
Vue.use(VueRx);
Vue.use(ApiPlugin);
Vue.use(Vuelidate);
Vue.use(VMask);
Vue.config.ignoredElements = [/amplify-\w*/];

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.filter("shortLocalDate", shortLocalDate);
Vue.filter("dashIfEmpty", dashIfEmpty);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
